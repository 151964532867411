import { api } from '../config';
import { web3 } from '../web3';
import { web3Services } from './web3.service';
import { uploadToS3, deleteToS3 } from '../s3.service';

export const backendServices = {
  get,
  post,
  put,
  //getWeb3,
  uploadFileOnBucket,
  remoeveFileOnBucket,
};

async function post(url, params) {
  const header = {
    'content-type': 'application/json',
  };
  const token = localStorage.getItem('token');
  if (token) {
    header['Authorization'] = token;
  }
  try {
    const response = await api.post(url, params, { headers: header });
    return response;
  } catch (error) {
    if (error.response) {
      return error.response;
    } else if (error.request) {
      return error.request;
    } else if (error.message) {
      return error.message;
    } else {
      return error;
    }
  }
}

async function get(url) {
  const header = {
    'content-type': 'application/json',
  };
  const token = localStorage.getItem('token');
  if (token) {
    header['Authorization'] = token;
  }
  try {
    const response = await api.get(url, { headers: header });
    return response;
  } catch (error) {
    return error;
  }
}

async function put(url, parameters) {
  const header = {
    'content-type': 'application/json',
  };
  const token = localStorage.getItem('token');
  if (token) {
    header['Authorization'] = token;
  }
  try {
    const response = await api.put(url, parameters, { headers: header });
    return response;
  } catch (error) {
    return error;
  }
}
/* 
async function getWeb3() {
  if (web3) {
    let web3Data = {
      isLoggedIn: false,
      accounts: [],
    };
    try {
      const responseData = await web3.eth.getAccounts(function (err, accounts) {
        if (err != null) {
          console.error("An error occurred: " + err);
        } else if (accounts.length === 0) {
          console.log("User is not logged in to MetaMask");
         // web3Services.enableMetamask()
        } else {
          console.log("User is logged in to MetaMask", accounts[0]);
        }
      });

      if (responseData.length) {
        web3Data.isLoggedIn = true;
        web3Data.accounts = responseData;
        return web3Data;
      } else {
        return web3Data;
      }
    } catch {
      return web3Data;
    }
  }
} */

async function uploadFileOnBucket(file, folder) {
  try {
    const extension = file.name.split('.').pop().toLowerCase();
    const uploadTo = await uploadToS3(
      `${folder}-${+new Date()}`,
      file,
      folder,
      extension,
    );
    return uploadTo.Location;
  } catch (error) {
    console.log(error);
    return false;
  }
}

async function remoeveFileOnBucket(fileName) {
  try {
    await deleteToS3(fileName);
  } catch (error) {
    console.log(error);
    return false;
  }
}
