import Web3 from 'web3';

let web3 = null;

/* const getWeb3 = async () => {
  // Check if browser is running Metamask
  if (window.ethereum) {
    web3 = new Web3(window.ethereum);
    const chainId = await web3.eth.getChainId();
    if (chainId !== 80001 && chainId !== '0x13881') {
      await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
          {
            chainId: '0x13881',
            chainName: 'Mumbai Testnet',
            nativeCurrency: {
              name: 'Binance Chain Token',
              symbol: 'MATIC',
              decimals: 18
            },
            rpcUrls: ['https://rpc-mumbai.maticvigil.com'],
          },
        ],
      });
      const changeRequest = window.ethereum.request({
        method: "wallet_switchEthereumChain",
        params: [{ chainId: "0x13881" }], // chainId must be in hexadecimal numbers
      });
    }
  } else if (window.web3) {
    web3 = new Web3(window.web3.currentProvider);
  } else {
    console.log('User is not downloaded in to MetaMask')
  }
};

if (!web3) {
  getWeb3();
} */

export {
  web3,
  //getWeb3,
};
