import React from 'react';
import { EditButton } from 'react-admin';

const NFTShow = ({ record }) => {
  return (
    <div>
      <h2>{record.event_name}</h2>
      {/* <img src={record.image.s3Url} alt={record.event_name} style={{ width: 200, height: 200 }} /> */}
      <p>{record.event_description}</p>
      <div>
        <strong>Event Start Date: </strong>
        <span>{new Date(record.event_start_date).toLocaleDateString()}</span>
        <EditButton basePath="/events" record={record} />
      </div>
      <div>
        <strong>Event End Date: </strong>
        <span>{new Date(record.event_end_date).toLocaleDateString()}</span>
        <EditButton basePath="/events" record={record} />
      </div>
      <div>
        <strong>Event Settlement Date: </strong>
        <span>
          {new Date(record.event_settlement_date).toLocaleDateString()}
        </span>
        <EditButton basePath="/events" record={record} />
      </div>
      <div>
        <strong>Category: </strong>
        <span>{record.category?.name}</span>
        <EditButton basePath="/events" record={record} />
      </div>
      <div>
        <strong>Is Active: </strong>
        <span>{record.is_active ? 'Yes' : 'No'}</span>
        <EditButton basePath="/events" record={record} />
      </div>
    </div>
  );
};

export default NFTShow;
