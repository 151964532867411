import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  Filter,
} from 'react-admin';
import { Link } from 'react-router-dom';

const PayField = ({ record }) => {
  if (record.payment_gateway === 'onmeta') {
    return <span>____</span>;
  } else {
    return (
      <span>
        {record.payAmount} {record.payCurrency}
      </span>
    );
  }
};
const PriceField = ({ record }) => {
  return (
    <span>
      {record.priceAmount} {record.priceCurrency}
    </span>
  );
};
const PriceFieldLocal = ({ record }) => {
  return (
    <span>
      {record.priceAmountLocal} {record.priceCurrencyLocal}
    </span>
  );
};
const DepositList = props => {
  const DepositsFilter = props => (
    <Filter {...props}>
      <TextField source="paymentStatus" defaultValue="waiting" />
    </Filter>
  );

  const rowStyle = record => {
    if (record.paymentStatus === 'finished') {
      return { backgroundColor: '#c8e6c9' }; // set background color to green
    } else {
      return {};
    }
  };
  const WaNumberField = ({ record }) => (
    <Link to={`/users/${record.user_id}/show`}>
      {record.waNumber ? record.waNumber : 'Email'}
    </Link>
  );

  return (
    <List
      {...props}
      filters={<DepositsFilter />}
      perPage={50}
      sort={{ field: 'updatedAt', order: 'DESC' }}
      allowToggleSorting
    >
      <Datagrid rowStyle={rowStyle}>
        <WaNumberField source="waNumber" sortable={false} />
        <TextField source="waName" sortable={false} />
        <TextField source="paymentStatus" sortable={false} />
        <PriceFieldLocal source="PaidLocal" sortable={false} />{' '}
        <TextField source="payment_gateway" sortable={false} />
        <DateField source="updatedAt" showTime sortable={false} />
        <PayField source="Crypto paid" sortable={false} />{' '}
        {/* Combined field */}
        <PriceField source="Paid_USD" sortable={false} /> {/* Combined field */}
        <TextField source="orderId" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default DepositList;
