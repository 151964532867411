import * as React from 'react';
import { List, Datagrid, TextField, EmailField, DateField } from 'react-admin';
import { useDataProvider } from 'react-admin';
import { useDispatch } from 'react-redux';
import { UPDATE } from 'react-admin';
const ContactList = props => {
  const dataProvider = useDataProvider();

  const [state, setState] = React.useState({
    contacts: [],
  });
  const dispatch = useDispatch();

  const handleRead = async id => {
    try {
      await dataProvider.update('contacts', {
        id: id,
        data: { isRead: true },
        previousData: { isRead: false },
      });
      dispatch({
        type: UPDATE,
        payload: { id, data: { isRead: true } },
        meta: { resource: 'contacts' },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const rowStyle = record => ({
    backgroundColor: record.isRead ? 'white' : '#f0f0f0',
  });

  return (
    <List {...props}>
      <Datagrid rowStyle={rowStyle} rowClick={id => handleRead(id)}>
        <TextField source="subject" />
        <EmailField source="email" />
        <TextField source="body" multiline="true" />
        <DateField source="date" showTime />
        <TextField source="isLoggedIn" />
      </Datagrid>
    </List>
  );
};

export default ContactList;
