import React from 'react';
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button';
import BackIcon from '@material-ui/icons/ArrowBack';

const BackButton = ({ history: { goBack }, ...props }) => (
  <Button {...props} onClick={goBack}>
    <BackIcon />
    {'Back'}
  </Button>
);

export default withRouter(BackButton);
