import { useState, useEffect, useRef } from 'react';
import fileToArrayBuffer from 'file-to-array-buffer';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { SaveContextProvider } from 'react-admin';
import moment from 'moment';

import {
  SimpleForm,
  DateInput,
  TextInput,
  useRedirect,
  useNotify,
  SelectInput,
  NumberInput,
  required,
  ImageInput,
  ImageField,
  SelectArrayInput,
  SimpleShowLayout,
  FileInput,
  FileField,
  useLocale,
  Button,
  BooleanInput,
} from 'react-admin';
import { actions } from '../../actions';
import Loader from '../loading';
import { services } from '../../services';
import { makeStyles } from '@material-ui/core/styles';

// Define styles
const useStyles = makeStyles({
  input: {
    width: '700px', // Set width
  },
  textArea: {
    width: '700px', // Set width
  },
});
const NFTCreate = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState({});
  const [token, setToken] = useState(null);
  const [callAPI, setCallAPI] = useState(false);
  const [buffer, setBuffer] = useState({ original: null, compressed: null });
  const [size, setSize] = useState({ original: 0, compressed: 0 });
  const [web3Data, setWeb3Data] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [ownerAdd, setOwnerAdd] = useState(null);
  const [nftContractInstance, setNftContractInstance] = useState(null);

  const [file, setFile] = useState(null);
  const videoInput = useRef();
  const videoElem = useRef();
  const locale = useLocale();

  const [showCurrencyBlock, setShowCurrencyBlock] = useState(false);
  const [isLive, setIsLive] = useState(false);
  const [currency, setCurrency] = useState('');
  const [date1, setDate1] = useState(null);
  const [date2, setDate2] = useState(null);

  const [icons] = useState([
    'cricket',
    'football',
    'tennis',
    'badminton',
    'basketball',
    'stocks',
    'sports',
    'crypto',
    'award',
    'movies',
    'film',
    'event',
    'vote',
    'trophy',
    'smartphone',
    'flight',
    'weather',
    'weather2',
    'weather3',
    'instagram',
    'youtube',
    'airplane',
  ]);
  useEffect(() => {
    async function loadCategories() {
      try {
        const response = await actions.getCategories();
        setCategories(response.data);
      } catch (error) {
        console.error('Error loading categories', error);
      }
    }
    loadCategories();
  }, []);

  useEffect(() => {
    console.log('data', data.category);
    if (data.category === '64e8326f13c71ee0516acdc2') {
      // Crypto
      setShowCurrencyBlock(true);
    } else {
      setShowCurrencyBlock(false);
      setIsLive(false);
    }
  }, [data.category]);

  async function createEvent() {
    try {
      data.event_start_date = moment(
        new Date(),
        'YYYY-MM-DDTHH:mm',
      ).toISOString();

      if (isLive) {
        data.eventDetail = {
          currency,
          check_date_1: date1,
          check_date_2: date2,
        };
      }

      const response = await services.post(`/events`, data);
      notify('Event created successfully');
      redirect('/events');
    } catch (error) {
      console.error('Error creating event', error);
    }
  }

  async function handleUploadFile(file) {
    if (file) {
      //setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await actions.upload(formData);
        setData({
          ...data,
          image: { s3Url: response.data.Location, format: uploadType },
        });
      } catch (error) {
        console.error('Error uploading file', error);
      } finally {
        //setLoading(false);
      }
    }
  }

  function handleSave(event) {
    event?.preventDefault?.();
    console.log('data', data);
    setCallAPI(true);
  }

  useEffect(() => {
    if (callAPI) {
      createEvent();
      setCallAPI(false);
    }
  }, [callAPI]);
  const classes = useStyles();

  return (
    <SaveContextProvider value={props}>
      <SimpleForm save={handleSave}>
        <h4>New Event</h4>

        <SimpleShowLayout>
          <TextInput
            className={classes.input} // Apply styles
            source="event_name"
            validate={required()}
            onChange={event =>
              setData({ ...data, event_name: event.target.value })
            }
          />
          <TextInput
            className={classes.textArea} // Apply styles
            multiline
            rows={8}
            format={value => (value ? value.replace(/\n/g, '<br />') : '')}
            parse={value => (value ? value.replace(/<br\s?\/?>/g, '\n') : '')}
            source="event_description"
            validate={required()}
            onChange={event =>
              setData({ ...data, event_description: event.target.value })
            }
          />
        </SimpleShowLayout>

        <h4>Marketplace Setting</h4>
        <SimpleShowLayout>
          <div style={{ marginBottom: '1rem' }}>
            <label>Close Date:</label>
            <input
              type="datetime-local"
              placeholder="Select End date and time"
              style={{ width: '100%' }}
              value={
                data.event_end_date
                  ? moment(data.event_end_date).format('YYYY-MM-DDTHH:mm')
                  : null
              }
              onChange={e => {
                setData({
                  ...data,
                  event_end_date: e.target.value
                    ? moment(e.target.value, 'YYYY-MM-DDTHH:mm').toISOString()
                    : null,
                });
              }}
            />
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <label>Settlement Date:</label>
            <input
              type="datetime-local"
              placeholder="Select Settlement date and time"
              style={{ width: '100%' }}
              value={
                data.event_settlement_date
                  ? moment(data.event_settlement_date).format(
                      'YYYY-MM-DDTHH:mm',
                    )
                  : null
              }
              onChange={e => {
                setData({
                  ...data,
                  event_settlement_date: e.target.value
                    ? moment(e.target.value, 'YYYY-MM-DDTHH:mm').toISOString()
                    : null,
                });
              }}
            />
          </div>
        </SimpleShowLayout>

        <h4>Category </h4>
        <SimpleShowLayout>
          <SelectInput
            source="category"
            choices={categories}
            validate={required()}
            optionValue="_id"
            optionText={type => `${type.name}`}
            onChange={event => {
              setData({ ...data, category: event.target.value });
            }}
          />
        </SimpleShowLayout>

        {showCurrencyBlock && (
          <>
            <h4>Currency Options</h4>
            <SimpleShowLayout>
              <BooleanInput
                label="Live"
                source="isLive"
                onChange={event => {
                  setIsLive(!isLive);
                }}
              />

              {isLive && (
                <>
                  <SelectInput
                    source="currency"
                    choices={[
                      'bitcoin',
                      'ethereum',
                      'polygon',
                      'dogecoin',
                      'shiba-inu',
                    ].map(cur => ({ id: cur, name: cur }))}
                    label="Currency"
                    validate={required()}
                    onChange={event => {
                      setCurrency(event.target.value);
                    }}
                  />
                  <input
                    type="datetime-local"
                    label="Date 1"
                    onChange={e => {
                      setDate1(e.target.value);
                    }}
                  />
                  <input
                    type="datetime-local"
                    label="Date 2"
                    onChange={e => {
                      setDate2(e.target.value);
                    }}
                  />
                </>
              )}
            </SimpleShowLayout>
          </>
        )}

        <h4>Event Icon </h4>
        <SimpleShowLayout>
          <SelectInput
            source="icon_name"
            choices={icons.map(icon => ({ value: icon, name: icon }))}
            validate={required()}
            optionValue="name"
            optionText={type => `${type.name}`}
            onChange={event => {
              setData({ ...data, icon_name: event.target.value });
            }}
          />
        </SimpleShowLayout>
      </SimpleForm>
    </SaveContextProvider>
  );
};

export default NFTCreate;
