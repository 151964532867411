import axios from 'axios';

// production server
export const api = axios.create({
  //baseURL: 'http://localhost:3010/api/v1/', // local url
  baseURL: 'https://api-futurebash.redentu.top/api/v1/', // production url
});

//export const apiUrl = 'http://localhost:3010/api/v1/'; // local url

export const apiUrl = 'https://api-futurebash.redentu.top/api/v1/'; // production url

// aws s3 bucket confiurations
export const awsRegion = 'eu-central-1';
export const awsIdentityPoolId =
  'eu-central-1:949b9487-ed35-4eb3-a2d3-1866e6ad890b';
export const awsBucket = 'avangrat-development';

export const pinataApi = axios.create({
  baseURL: 'https://api.pinata.cloud', // Server url
});

export const pinataJsonService = async data => {
  const header = {
    'content-type': 'application/json',
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI3Yjg3NGVlMi0yNTkwLTQ0YzktYmY1Zi03MWNjNmI3NDdkYmMiLCJlbWFpbCI6Imluc3RhMmtjb29rQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiJiMzE2YzFmZmVkZmQ4ZTdkNzczYiIsInNjb3BlZEtleVNlY3JldCI6IjJkMWEzMDFmYmUwMzFhMDAzMGY3NzllMGY3YWQ2MWNlYWNmNjYxYjQzOTk5MGVkZDU2ODNmYzY1MmE4YjgzY2EiLCJpYXQiOjE2Njc4OTMxOTR9.unQDcCts27KGHxQ2c_URhKIIYJOfOGpjwLB9xRw9puw',
  };
  return pinataApi.post('/pinning/pinJSONToIPFS', data, { headers: header });
};

export const pinataImageService = async data => {
  const header = {
    'Content-Type': 'multipart/form-data',
    Authorization:
      'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySW5mb3JtYXRpb24iOnsiaWQiOiI3Yjg3NGVlMi0yNTkwLTQ0YzktYmY1Zi03MWNjNmI3NDdkYmMiLCJlbWFpbCI6Imluc3RhMmtjb29rQGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJwaW5fcG9saWN5Ijp7InJlZ2lvbnMiOlt7ImlkIjoiRlJBMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfSx7ImlkIjoiTllDMSIsImRlc2lyZWRSZXBsaWNhdGlvbkNvdW50IjoxfV0sInZlcnNpb24iOjF9LCJtZmFfZW5hYmxlZCI6ZmFsc2UsInN0YXR1cyI6IkFDVElWRSJ9LCJhdXRoZW50aWNhdGlvblR5cGUiOiJzY29wZWRLZXkiLCJzY29wZWRLZXlLZXkiOiJiMzE2YzFmZmVkZmQ4ZTdkNzczYiIsInNjb3BlZEtleVNlY3JldCI6IjJkMWEzMDFmYmUwMzFhMDAzMGY3NzllMGY3YWQ2MWNlYWNmNjYxYjQzOTk5MGVkZDU2ODNmYzY1MmE4YjgzY2EiLCJpYXQiOjE2Njc4OTMxOTR9.unQDcCts27KGHxQ2c_URhKIIYJOfOGpjwLB9xRw9puw',
  };
  return pinataApi.post('/pinning/pinFileToIPFS', data, { headers: header });
};
