import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import { apiUrl } from '../config';
import { services } from '../services';
import ipfs from '../config/ipfs';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }
  const token = localStorage.getItem('token');
  options.headers.set('Authorization', `${token}`);
  return fetchUtils.fetchJson(url, options);
};

const dataProvider = {
  getList: (resource, params) => {
    let url = '';
    let query = [];
    query.push(`page=${params.pagination.page}`);
    if (params.sort) {
      const { field, order } = params.sort;

      if (field) {
        query.push(`sort=${field}&order=${order}`);
      }
    }
    if (resource === 'users') {
      Object.keys(params.filter).map(key =>
        query.push('waNumber=' + params.filter[key]),
      );
      query = query.join('&');
      url = `${apiUrl}users/list?${query}`;
    } else if (resource === 'categories') {
      url = `${apiUrl}category`;
    } else if (resource === 'events') {
      // Add filters to the query array
      Object.keys(params.filter).forEach(key => {
        query.push(`${key}=${encodeURIComponent(params.filter[key])}`);
      });
      const queryString = query.join('&');
      url = `${apiUrl}events/admin?${queryString}`;
    } else if (resource === 'live-events') {
      url = `${apiUrl}live-events`;
    } else if (resource === 'banners') {
      url = `${apiUrl}admin/banner/listForAdmin`;
    } else if (resource === 'profile-info') {
      url = `${apiUrl}admin/profile-info/listForAdmin`;
    } else if (resource === 'info') {
      url = `${apiUrl}admin/info/listForAdmin`;
    } else if (resource === 'hall-frame-info') {
      url = `${apiUrl}admin/hall-frame-info/listForAdmin`;
    } else if (resource === 'collections') {
      url = `${apiUrl}nft/listCollectionForAdmin?${query}`;
    } else if (resource === 'top-nft') {
      url = `${apiUrl}admin/popular/listForAdmin`;
    } else if (resource === 'top-collection') {
      url = `${apiUrl}admin/popularCollection/listForAdmin`;
    } else if (resource === 'top-brands') {
      url = `${apiUrl}brand/listForAdmin`;
    } else if (resource === 'bets') {
      if (params.id === 'bets') {
        url = `${apiUrl}bets/allBetsAdmin?${query}`;
      }
      // Check if params.id exists before constructing the URL
      else if (params.id) {
        url = `${apiUrl}bets/admin/${params.id}`;
      } else {
        // Return an empty Promise with the data you expect to receive
        return Promise.resolve({
          data: [],
          total: 0,
        });
      }
    } else if (resource === 'predictions') {
      Object.keys(params.filter).map(key => {
        if (key === 'eventId') {
          query.push('eventId=' + params.filter[key]);
        }
      });
      query = query.join('&');
      url = `${apiUrl}bets/allBetsAdmin?${query}`;
    } else if (resource === 'deposits') {
      url = `${apiUrl}transactions/allDeposits`;
    } else if (resource === 'withdrawals') {
      url = `${apiUrl}transactions/allWithdrawals`;
    } else if (resource === 'contacts') {
      url = `${apiUrl}contact/list`;
    } else if (resource === 'blog') {
      url = `${apiUrl}blog`;
    }

    console.log('url', url);
    return httpClient(url).then(response => {
      console.log('response', response);
      return {
        data: response?.json?.data,
        total: parseInt(response.json.pagination.totalRecords),
      };
    });
  },

  getOne: (resource, params) => {
    let url = '';
    if (resource === 'users') {
      console.log('inside users', params.id);
      url = `${apiUrl}users/details/${params.id}`;
    } else if (resource === 'categories') {
      url = `${apiUrl}category/${params.id}`;
    } else if (resource === 'events') {
      url = `${apiUrl}events/${params.id}`;
    } else if (resource === 'live-events') {
      url = `${apiUrl}live-events/${params.id}`;
    } else if (resource === 'banners') {
      url = `${apiUrl}admin/banner/details/${params.id}`;
    } else if (resource === 'profile-info') {
      url = `${apiUrl}admin/profile-info/details/${params.id}`;
    } else if (resource === 'info') {
      url = `${apiUrl}admin/info/details/${params.id}`;
    } else if (resource === 'hall-frame-info') {
      url = `${apiUrl}admin/hall-frame-info/details/${params.id}`;
    } else if (resource === 'collections') {
      url = `${apiUrl}nft/collection/${params.id}`;
    } else if (resource === 'blog') {
      url = `${apiUrl}blog/${params.id}`;
    } else if (resource === 'nft') {
      url = `${apiUrl}events/${params.id}`;
    } else if (resource === 'top-nft') {
      url = `${apiUrl}admin/popular/listPopularDetails/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: json.data[0],
      }));
    } else if (resource === 'top-collection') {
      url = `${apiUrl}admin/popularCollection/listPopularDetails/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: json.data[0],
      }));
    } else if (resource === 'top-brands') {
      url = `${apiUrl}brand/${params.id}`;
      return httpClient(url).then(({ json }) => ({
        data: json,
      }));
    }
    return httpClient(url).then(({ json }) => {
      console.log('data from api is:::', json);
      if (json.data) {
        return {
          data: json.data,
        };
      } else {
        return {
          data: json,
        };
      }
    });
  },

  getMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    const url = `${apiUrl}${resource}?${stringify(query)}`;
    return httpClient(url).then(({ json }) => ({ data: json }));
  },

  getManyReference: (resource, params) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;
    const query = {
      sort: JSON.stringify([field, order]),
      range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
      filter: JSON.stringify({
        ...params.filter,
        [params.target]: params.id,
      }),
    };
    const url = `${apiUrl}/${resource}?${stringify(query)}`;

    return httpClient(url).then(({ headers, json }) => ({
      data: json,
      total: parseInt(headers.get('content-range').split('/').pop(), 10),
    }));
  },

  update: (resource, params) => {
    let url = '';

    if (resource === 'contacts') {
      url = `${apiUrl}contact/${params.id}`;
    }

    return httpClient(url, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => {
      console.log('Update response:', json);
      return { data: { id: json.data.id, ...json.data } };
    });
  },

  updateMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'PUT',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },

  create: (resource, params) => {
    let url = '';
    if (resource === 'nft') {
      url = `${apiUrl}nft/addNft`;
    }

    return httpClient(url, {
      method: 'POST',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({
      data: { ...params.data, id: json.id },
    }));
  },

  delete: async (resource, params) => {
    let url = '';
    let fileName = '';
    if (resource === 'banners') {
      url = `${apiUrl}admin/banner/delete/${params.id}`;
    } else if (resource === 'info') {
      url = `${apiUrl}admin/info/delete/${params.id}`;
    } else if (resource === 'hall-frame-info') {
      url = `${apiUrl}admin/hall-frame-info/delete/${params.id}`;
    } else if (resource === 'profile-info') {
      url = `${apiUrl}admin/profile-info/delete/${params.id}`;
    } else if (resource === 'top-nft') {
      url = `${apiUrl}admin/popular/delete/${params.id}`;
    } else if (resource === 'top-collection') {
      url = `${apiUrl}admin/popularCollection/delete/${params.id}`;
    }

    return httpClient(url, {
      method: 'DELETE',
    }).then(({ json }) => ({ data: json }));
  },

  deleteMany: (resource, params) => {
    const query = {
      filter: JSON.stringify({ id: params.ids }),
    };
    return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
      method: 'DELETE',
      body: JSON.stringify(params.data),
    }).then(({ json }) => ({ data: json }));
  },
};

export default dataProvider;
