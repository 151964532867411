import {
  Button,
  useNotify,
  useRefresh,
  useRedirect,
  SimpleForm,
  TextField,
  TextInput,
  required,
} from 'react-admin';
import { services } from '../../services';
import { actions } from '../../actions';
import { useState, useEffect } from 'react';
import Loader from '../loading';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

const ApproveUser = ({ record }) => {
  const [transactionId, setTransactionId] = useState(null);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [apiCall, setAPICalled] = useState(false);
  const [web3Data, setWeb3Data] = useState(null);
  const [ownerAdd, setOwnerAdd] = useState(null);
  const [fee, setFee] = useState(null);
  const [nftContractInstance, setNftContractInstance] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const handleClick = () => {
    setShowDialog(true);
  };

  const handleCloseClick = () => {
    setShowDialog(false);
  };

  const onSuccessForm = async event => {
    setFee(+event.fee);
    onTransaction([record.walletAddress], event.fee);
  };

  useEffect(() => {
    async function getNftContractInstance() {
      const nftContractInstance = await actions.getNFTContractInstance();
      setNftContractInstance(nftContractInstance);
      const adminAddress = await nftContractInstance.methods.admin().call();
      setOwnerAdd(adminAddress);
    }
    getNftContractInstance();

    async function getWeb3Data() {
      const web3Data = await services.getWeb3();
      setWeb3Data(web3Data);
    }
    getWeb3Data();

    async function approveCreator() {
      handleCloseClick();
      const user = [
        {
          id: record.id,
          status: status,
        },
      ];
      const params = { user: user, transactionId: transactionId, fee };
      const request = services.post('/user/aprrove', params);
      request
        .then(response => {
          if (response.status < 200 || response.status >= 300) {
            setLoading(false); // stop loading.
            throw new Error(response.statusText);
          }
          if (response.status === 200) {
            setLoading(false); // stop loading.
            // console.log(response.data)
            notify(response.data.message, `success`);
            refresh();
          }
        })
        .catch(e => {
          setLoading(false); // stop loading.
          if (e.response) {
            if (e.response.status === 401 || e.response.status === 403) {
              notify(`Unauthorized Request`, `error`);
              localStorage.removeItem('token');
              refresh();
            }
            // other error code (404, 500, etc): no need to log out
            return Promise.resolve();
          } else {
            return Promise.resolve();
          }
        });
    }

    if (apiCall) {
      approveCreator();
    }
  }, [transactionId, apiCall]);

  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onTransaction = async (walletAddress, fee) => {
    if (web3Data.accounts[0] === ownerAdd) {
      await nftContractInstance.methods
        .approveCreators(walletAddress, [+fee])
        .send({ from: web3Data.accounts[0] })
        .on('transactionHash', hash => {
          console.log('transaction hash : ', hash);
          setTransactionId(hash);
          setLoading(true); // start loading.
        })
        .on('receipt', receipt => {
          console.log('on receipt ', receipt);
          setStatus(true);
          setAPICalled(true);
        })
        .on('error', error => {
          setAPICalled(true);
          console.log('on error ', error); // error.code===4001 user reject the metamask transaction
        });
    } else {
      notify(`User is not an owner of the contract`, `warning`);
      redirect('/users');
      return false;
    }
  };

  const onSuccess = async () => {
    if (!record.walletAddress) {
      return notify(`User is not connected to any wallet`, `warning`);
    }
    handleClick();
  };

  if (record.status === 'PENDING') {
    return loading ? (
      <Loader />
    ) : (
      <>
        <Dialog
          fullWidth
          open={showDialog}
          onClose={handleCloseClick}
          aria-label="Set Fee"
        >
          <DialogTitle>Set Fee</DialogTitle>
          <DialogContent>
            <SimpleForm save={onSuccessForm}>
              <TextField source="Fee" sortable={false} />
              <TextInput source="fee" validate={required()} />
            </SimpleForm>
          </DialogContent>
          <DialogActions>
            <Button label="Cancel" onClick={handleCloseClick}></Button>
          </DialogActions>
        </Dialog>
        <Button color="primary" onClick={onSuccess} label="Approve" />
      </>
    );
  } else {
    return record.status;
  }
};

export default ApproveUser;
