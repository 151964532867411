// noinspection JSUnusedLocalSymbols

import { useEffect, useRef, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import {
  BooleanInput,
  SaveContextProvider,
  SimpleForm,
  SimpleShowLayout,
  useLocale,
  useNotify,
  useRedirect,
} from 'react-admin';
import moment from 'moment';
import { actions } from '../../actions';
import { services } from '../../services';
import dataProvider from '../../authentication/dataProvider';

const LiveEventEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});
  const [callAPI, setCallAPI] = useState(false);
  const [LiveEventContractInstance, setLiveEventContractInstance] =
    useState(null);

  const locale = useLocale();
  const [event, setEvent] = useState(null);

  useEffect(() => {
    async function fetchLiveEvent() {
      try {
        const response = await dataProvider.getOne('live-events', {
          id: props.id,
        });
        setData(response.data);
      } catch (error) {
        console.error('Error loading categories', error);
      }
    }

    fetchLiveEvent();
  }, []);

  async function updateLiveEvent() {
    try {
      const response = await services.put(`/live-events/${props.id}`, data);
      notify('Event updated successfully');
      redirect('/live-events');
    } catch (error) {
      console.error('Error updating event', error);
    }
  }

  function handleSave(event) {
    event?.preventDefault?.();
    console.log('data', data);
    setCallAPI(true);
  }

  useEffect(() => {
    if (callAPI) {
      updateLiveEvent();
      setCallAPI(false);
    }
  }, [callAPI]);

  return (
    <SaveContextProvider value={props}>
      <SimpleForm save={handleSave}>
        <h4>Edit Live Event</h4>

        <h4>{data.event_name}</h4>

        <SimpleShowLayout>
          <div style={{ marginBottom: '1rem' }}>
            <label>Start Date: </label>
            <input
              type="datetime-local"
              placeholder="Select Start date and time"
              style={{ width: '250px' }}
              value={
                data.event_start_date
                  ? moment(data.event_start_date).format('YYYY-MM-DDTHH:mm')
                  : null
              }
              onChange={e => {
                setData({
                  ...data,
                  event_start_date: e.target.value
                    ? moment(e.target.value, 'YYYY-MM-DDTHH:mm').toISOString()
                    : null,
                });
              }}
            />
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <label>End Date: </label>
            <input
              type="datetime-local"
              placeholder="Select End date and tim"
              style={{ width: '250px' }}
              value={
                data.event_end_date
                  ? moment(data.event_end_date).format('YYYY-MM-DDTHH:mm')
                  : null
              }
              onChange={e => {
                setData({
                  ...data,
                  event_end_date: e.target.value
                    ? moment(e.target.value, 'YYYY-MM-DDTHH:mm').toISOString()
                    : null,
                });
              }}
            />
          </div>
        </SimpleShowLayout>

        <BooleanInput
          source="is_active"
          label="Is Active"
          onChange={value => {
            setData({ ...data, is_active: value });
          }}
          defaultValue={data.is_active}
        />
      </SimpleForm>
    </SaveContextProvider>
  );
};

export default LiveEventEdit;
