import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDataProvider } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../loading';
import { services } from '../../services';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControl,
  InputLabel,
  RadioGroup,
  Radio,
  FormControlLabel,
  TextField,
} from '@material-ui/core';

import { Datagrid, DateField, NumberField, ReferenceField } from 'react-admin';

import DialogContentText from '@material-ui/core/DialogContentText';

const useStyles = makeStyles({
  root: {
    maxWidth: 800,
    margin: '0 auto',
  },
});

const BetsList = props => {
  console.log('props', props);
  const location = useLocation();
  const classes = useStyles();
  const [bets, setBets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [settlementType, setSettlementType] = useState('');
  const [totalBetAmount, setTotalBetAmount] = useState(0);
  const [totalYesBetAmount, setTotalYesBetAmount] = useState(0);
  const [totalNoBetAmount, setTotalNoBetAmount] = useState(0);
  const [settlementAmounts, setSettlementAmounts] = useState({});
  const [allEvents, setAllEvents] = useState(false);
  const [isEventSettled, setIsEventSettled] = useState(false);
  const [isEventCancelled, setIsEventCancelled] = useState(false);

  const { state } = location;

  const eventStatus = state;

  const dataProvider = useDataProvider();

  const [openPlaceBets, setOpenPlaceBets] = useState(false);
  const [betAmount, setBetAmount] = useState(0);
  const [betResult, setBetResult] = useState('Yes');
  const [eventName, setEventName] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [openCancel, setOpenCancel] = React.useState(false);

  const handlePlaceBetsClick = () => {
    setOpenPlaceBets(true);
  };

  const handleClosePlaceBets = () => {
    setOpenPlaceBets(false);
  };

  const handleBetAmountChange = event => {
    setBetAmount(event.target.value);
  };

  const handlePlaceBetsSubmit = async () => {
    setLoading(true); // start loader
    let event_id = location.pathname.split('/').pop();

    const params = {
      event_id,
      bet_amount: betAmount,
      bet_result: betResult,
    };

    console.log('params ', params);

    try {
      const bet_response = await services.post(
        `events/createBet/${event_id}`,
        params,
      );
      console.log(bet_response.data);

      // Assuming the response is successful, update UI accordingly
      handleClosePlaceBets();
      setSuccessMessage('Bet placed successfully!');
      // Uncomment the next lines if you want to reload the page
      setTimeout(() => {
        window.location.reload(); // Refresh the page after a delay
      }, 2000);
    } catch (error) {
      console.error('Error placing bet:', error);
      setSuccessMessage('Error placing bet. Please try again.');
    } finally {
      setLoading(false); // stop loading.
    }

    // TODO: Further handling or state updates based on bet_response
  };

  useEffect(() => {
    const fetchBets = async () => {
      try {
        setLoading(true);
        let event_id = location.pathname.split('/').pop();
        console.log('event_id', event_id);
        const searchParams = new URLSearchParams(props.location.search);
        const eventName = searchParams.get('event_name');
        setEventName(eventName);

        let response;

        if (event_id == 'bets') {
          setAllEvents(true);
          response = await dataProvider.getList('bets', {
            id: 'bets',
            pagination: { page: null },
          });
        } else {
          response = await dataProvider.getList('bets', {
            id: event_id,
            pagination: { page: null },
          });
        }
        console.log('response data is::', response.data);

        setBets(response.data);

        setLoading(false);
      } catch (error) {
        console.error(error);
        setLoading(false);
      }
    };
    fetchBets();
  }, [location]);

  useEffect(() => {
    let totalBetAmountWithResultYes = 0;
    let totalBetAmountWithResultNo = 0;
    bets.forEach(bet => {
      if (bet.bet_result === 'Yes') {
        totalBetAmountWithResultYes += bet.bet_amount;
      } else if (bet.bet_result === 'No') {
        totalBetAmountWithResultNo += bet.bet_amount;
      }
    });
    setTotalBetAmount(totalBetAmountWithResultYes + totalBetAmountWithResultNo);

    setTotalYesBetAmount(totalBetAmountWithResultYes);

    setTotalNoBetAmount(totalBetAmountWithResultNo);
  }, [bets]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSettlementTypeChange = event => {
    setSettlementType(event.target.value);
  };

  const handleSettleEvent = async () => {
    setLoading(true); // start loader
    let event_id = location.pathname.split('/').pop();

    const params = {
      id: event_id,
      settlementType,
    };
    console.log('params ', params);
    const response = await services.post(`events/settleEvent`, params);
    console.log(response.data);

    setLoading(false); // stop loading.

    // TODO: update the settlement amount in the backend
    //console.log(settlementAmounts);
    setIsEventSettled(true);

    handleClose();
  };

  const handleCancelEvent = async () => {
    setLoading(true); // start loader
    let event_id = location.pathname.split('/').pop();

    const params = {
      id: event_id,
    };

    try {
      console.log('Cancel params ', params);
      const response = await services.post(`events/cancelEvent`, params);
      console.log(response.data);

      // TODO: Perform additional tasks upon successful cancellation, if needed

      setIsEventCancelled(true); // Assuming you have a state variable for this
    } catch (error) {
      console.error('Error cancelling the event: ', error);

      // TODO: Handle the error condition, maybe update the UI to indicate failure
    } finally {
      setLoading(false); // stop loading
      handleClose(); // close the modal or dialog, if any
    }
  };

  const PlaceBetForm = () => {
    const handleBetResultChange = event => {
      setBetResult(event.target.value);
    };
    // Improved validation for betAmount
    const isValidBetAmount = amount => {
      const regex = /^[0-9,]+(\.[0-9]{1,2})?$/;
      return regex.test(amount);
    };
    return (
      <div>
        {successMessage && (
          <div style={{ color: 'green' }}>{successMessage}</div>
        )}

        <FormControl style={{ display: 'inline-flex' }}>
          <div style={{ marginBottom: '50px' }}>
            <InputLabel htmlFor="bet-amount">Bet Amount</InputLabel>
          </div>
          <TextField
            id="bet-amount"
            type="text"
            value={betAmount}
            onChange={handleBetAmountChange}
            style={{ marginRight: '10px' }}
            error={!isValidBetAmount(betAmount)}
            helperText={
              !isValidBetAmount(betAmount) ? 'Invalid Bet Amount' : ''
            }
          />
        </FormControl>

        <FormControl>
          <RadioGroup
            aria-label="Bet Result"
            name="betResult"
            value={betResult}
            onChange={handleBetResultChange}
          >
            <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
            <FormControlLabel value="No" control={<Radio />} label="No" />
          </RadioGroup>
        </FormControl>

        <div style={{ marginTop: '10px' }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handlePlaceBetsSubmit}
            disabled={!isValidBetAmount(betAmount)}
          >
            Submit
          </Button>
        </div>
      </div>
    );
  };

  if (loading) {
    return <Loader />;
  }

  return (
    <div className={classes.root}>
      {!allEvents && (
        <>
          {bets.length >= 0 && (
            <div>
              <h2>{eventName}</h2>
              {/* <h6>Event ID: {bets[0].event_id}</h6> */}
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: '10px' }}>
                  <div style={{ backgroundColor: '#f2f2f2', padding: '10px' }}>
                    <p style={{ fontSize: '14px' }}>
                      Bet Amount (Yes): ${totalYesBetAmount.toFixed(2)}
                    </p>
                    <p style={{ fontSize: '14px' }}>
                      Total Bet Amount: ${totalBetAmount.toFixed(2)}
                    </p>
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: '#00b300',
                      }}
                    >
                      YES:{' '}
                      {((0.9 * totalBetAmount) / totalYesBetAmount).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div>
                  <div style={{ backgroundColor: '#f2f2f2', padding: '10px' }}>
                    <p style={{ fontSize: '14px' }}>
                      Bet Amount (No): ${totalNoBetAmount.toFixed(2)}
                    </p>
                    <p style={{ fontSize: '14px' }}>
                      Total Bet Amount: ${totalBetAmount.toFixed(2)}
                    </p>
                    <p
                      style={{
                        fontSize: '18px',
                        fontWeight: 'bold',
                        color: '#ff1a1a',
                      }}
                    >
                      NO:{' '}
                      {((0.9 * totalBetAmount) / totalNoBetAmount).toFixed(2)}
                    </p>
                  </div>
                </div>
              </div>

              <Button
                variant="contained"
                color="primary"
                onClick={handleOpen}
                disabled={isEventSettled || eventStatus === 'settled'}
              >
                {isEventSettled
                  ? 'Event is already settled'
                  : eventStatus === 'settled'
                    ? 'Event is already settled'
                    : 'Settle this event'}
              </Button>

              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpenPlaceBets(true)}
                style={{ marginLeft: '10px' }}
              >
                Place Bets
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenCancel(true)}
                disabled={isEventCancelled || eventStatus === 'canceled'}
                style={{ marginLeft: '25px' }}
              >
                {isEventCancelled
                  ? 'Event is already Canceled'
                  : eventStatus === 'canceled'
                    ? 'Event is already canceled'
                    : 'Cancel this event'}
              </Button>

              <Dialog open={openCancel} onClose={() => setOpenCancel(false)}>
                <DialogTitle>{'Cancel Event?'}</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    Are you sure you want to cancel this event?
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={() => setOpenCancel(false)} color="primary">
                    No
                  </Button>
                  <Button
                    onClick={() => {
                      handleCancelEvent();
                      setOpenCancel(false);
                    }}
                    color="primary"
                  >
                    Yes
                  </Button>
                </DialogActions>
              </Dialog>

              {openPlaceBets && <PlaceBetForm />}
            </div>
          )}
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Email</TableCell>

                <TableCell>Bet</TableCell>
                <TableCell> Amount</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Settlement</TableCell>
                <TableCell>ID</TableCell>
                <TableCell>User ID</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bets.map(bet => (
                <TableRow
                  key={bet._id}
                  style={
                    bet.user_email === 'varun@fananywhere.com' ||
                    bet.user_email === 'amit.iit15@gmail.com'
                      ? { backgroundColor: '#ADD8E6' }
                      : {}
                  }
                >
                  <TableCell>{bet.user_email}</TableCell>

                  <TableCell>{bet.bet_result}</TableCell>
                  <TableCell>{bet.bet_amount}</TableCell>
                  <TableCell>
                    {new Date(bet.createdAt).toLocaleDateString()}
                  </TableCell>
                  <TableCell
                    style={
                      bet.status === 'win'
                        ? { backgroundColor: '#c8e6c9' }
                        : { backgroundColor: '#ffcdd2' }
                    }
                  >
                    {bet.win_amount}
                  </TableCell>
                  <TableCell>{bet._id}</TableCell>
                  <TableCell>{bet.user_id}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Settlement Type</DialogTitle>
            <DialogContent>
              <RadioGroup
                aria-label="Settlement Type"
                name="settlementType"
                value={settlementType}
                onChange={handleSettlementTypeChange}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSettleEvent} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}

      {allEvents && (
        <>
          {bets.length > 0 && <div></div>}
          {
            <h2>Visit Predictions screen</h2>
            /* <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Created At</TableCell>

                <TableCell>Event ID</TableCell>
                <TableCell>Status</TableCell>

                <TableCell>User ID</TableCell>
                <TableCell>Bet Result</TableCell>
                <TableCell>Amount</TableCell>
                <TableCell>Main </TableCell>
                <TableCell>Bonus </TableCell>

                <TableCell>Settlement Amount</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {bets.map((bet) => (
                <TableRow
                  key={bet.id}
                  style={
                    bet.user_email === "varun@fananywhere.com" ||
                    bet.user_email === "amit.iit15@gmail.com"
                      ? { backgroundColor: "#ADD8E6" }
                      : {}
                  }
                >
                  <TableCell>{bet.id}</TableCell>
                  <TableCell>
                    {new Date(bet.createdAt).toLocaleString()}
                  </TableCell>
                  <TableCell>{bet.event_name}</TableCell>
                  <TableCell>{bet.status}</TableCell>

                  <TableCell>{bet.user_email}</TableCell>
                  <TableCell>{bet.bet_result}</TableCell>
                  <TableCell>{bet.bet_amount}</TableCell>
                  <TableCell>{bet.main_amount}</TableCell>
                  <TableCell>{bet.bonus_amount}</TableCell>

                  <TableCell
                    style={
                      bet.status === "win"
                        ? { backgroundColor: "#c8e6c9" }
                        : { backgroundColor: "#ffcdd2" }
                    }
                  >
                    {bet.win_amount}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table> */
          }
          <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Settlement Type</DialogTitle>
            <DialogContent>
              <RadioGroup
                aria-label="Settlement Type"
                name="settlementType"
                value={settlementType}
                onChange={handleSettlementTypeChange}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button onClick={handleSettleEvent} color="primary">
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </div>
  );
};
export default BetsList;
