import React, { useState } from 'react';
import {
  useRecordContext,
  List,
  Datagrid,
  TextField,
  DateField,
  NumberField,
  Filter,
  EditButton,
  useRefresh,
} from 'react-admin';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  TextField as FormTextField,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import { IconButton } from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { services } from '../../services';
import { CircularProgress } from '@material-ui/core';

const PayField = ({ record }) => {
  return (
    <span>
      {record.payAmount} {record.payCurrency}
    </span>
  );
};
const PriceField = ({ record }) => {
  return (
    <span>
      {record.priceAmount} {record.priceCurrency}
    </span>
  );
};
const PriceFieldLocal = ({ record }) => {
  return (
    <span>
      {record.priceAmountLocal} {record.priceCurrencyLocal}
    </span>
  );
};

const WithdrawalList = props => {
  const [processingDialogOpen, setProcessingDialogOpen] = useState(false);
  const [selectedWithdrawal, setSelectedWithdrawal] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const ProcessWithdrawalButton = ({ record, onClick }) => {
    if (record.paymentStatus !== 'waiting') {
      return null; // Don't render anything if payment status is not "waiting"
    }

    return (
      <Button
        color="primary"
        variant="outlined"
        onClick={event => {
          event.stopPropagation(); // Prevent row click action
          onClick(record);
        }}
      >
        Process
      </Button>
    );
  };

  const WithdrawalsFilter = props => (
    <Filter {...props}>
      <TextField source="paymentStatus" defaultValue="waiting" />
    </Filter>
  );

  const rowStyle = record => {
    if (record.paymentStatus === 'settled') {
      return { backgroundColor: '#c8e6c9' }; // set background color to green
    } else {
      return {};
    }
  };
  const PayAddressField = ({ record }) => (
    <>
      <span>{record.payAddress}</span>
      <CopyToClipboard text={record.payAddress}>
        <IconButton aria-label="Copy to clipboard">
          <FileCopyIcon />
        </IconButton>
      </CopyToClipboard>
    </>
  );
  const WaNumberField = ({ record }) => (
    <Link to={`/users/${record.userId}/show`}>
      {record.waNumber ? record.waNumber : 'Email'}
    </Link>
  );

  const handleClearSelection = () => {
    setSelectedWithdrawal(null);
  };

  /* const handleProcessWithdrawal = (id,record) => {
    console.log("record",record)
    setSelectedWithdrawal(id);
    setProcessingDialogOpen(true);
  }; */
  /*  const handleProcessWithdrawal = (id, basePath, record) => {
    console.log("record", id, record, basePath);
    setSelectedWithdrawal(record);
    setProcessingDialogOpen(true);
  }; */

  const handleProcessWithdrawal = record => {
    console.log('record', record);
    setSelectedWithdrawal(record);
    setProcessingDialogOpen(true);
  };

  const handleWithdrawalProcessed = () => {
    setSelectedWithdrawal(null);
    setProcessingDialogOpen(false);
  };
  async function updateWithdrawTransaction(
    transactionId,
    transactionDetails,
    transactionAmount,
  ) {
    const url = `/transactions/updateWithdrawal/${transactionId}`;
    const parameters = {
      paymentStatus: 'settled',
      actuallyPaid: transactionAmount,
      withdrawalDetail: transactionDetails,
    };
    console.log('url', url);
    const response = await services.put(url, parameters);
    console.log(response);
    return response;
  }

  const WithdrawalProcessingForm = ({ withdrawal, onClose }) => {
    console.log(withdrawal);
    const refresh = useRefresh();

    const [transactionDetails, setTransactionDetails] = useState('');
    const [transactionAmount, setTransactionAmount] = useState('');

    const handleTransactionDetailsChange = event => {
      setTransactionDetails(event.target.value);
    };

    const handleTransactionAmountChange = event => {
      setTransactionAmount(event.target.value);
    };

    const handleFormSubmit = async event => {
      event.preventDefault();
      setIsSubmitting(true); // set to true before API call

      await updateWithdrawTransaction(
        selectedWithdrawal._id,
        transactionDetails,
        transactionAmount,
      );

      setIsSubmitting(false); // set back to false after API call
      onClose();
      refresh();
    };

    return (
      <Dialog open={processingDialogOpen} onClose={handleWithdrawalProcessed}>
        <DialogTitle>
          {selectedWithdrawal.payment_gateway} Withdrawal
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter transaction details and amount for the withdrawal request.
          </DialogContentText>
          {/* Displaying price information */}
          <DialogContentText>
            <b>
              PAY TO SETTLE: {selectedWithdrawal.payAmount}{' '}
              {selectedWithdrawal.payCurrency}
            </b>
          </DialogContentText>

          <DialogContentText>
            {selectedWithdrawal.priceAmount} USD OR{' '}
            {selectedWithdrawal.priceAmountLocal} INR
          </DialogContentText>
          <DialogContentText>
            <b>{selectedWithdrawal.payAddress}</b>
          </DialogContentText>

          <form onSubmit={handleFormSubmit}>
            <FormTextField
              label="Transaction Hash"
              value={transactionDetails}
              onChange={handleTransactionDetailsChange}
              fullWidth
              margin="normal"
              required
            />
            <FormTextField
              label="Transaction Amount"
              value={transactionAmount}
              onChange={handleTransactionAmountChange}
              fullWidth
              margin="normal"
              required
            />
            <DialogActions>
              <Button onClick={handleWithdrawalProcessed} color="primary">
                Cancel
              </Button>

              <Button type="submit" color="primary" disabled={isSubmitting}>
                Save
              </Button>

              {isSubmitting && <CircularProgress size={24} />}
            </DialogActions>
          </form>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <>
      <List
        {...props}
        filters={<WithdrawalsFilter />}
        perPage={50}
        sort={{ field: 'updatedAt', order: 'DESC' }}
        allowToggleSorting
        refresh={true}
      >
        <Datagrid rowStyle={rowStyle}>
          <TextField source="paymentStatus" sortable={false} />
          <ProcessWithdrawalButton
            label="Process Withdrawal"
            onClick={handleProcessWithdrawal}
          />
          <DateField source="updatedAt" sortable={false} showTime />
          <WaNumberField source="waNumber" sortable={false} />
          <PayAddressField source="payAddress" sortable={false} />
          <TextField source="network" sortable={false} />
          <PayField source="pay" sortable={false} /> {/* Combined field */}
          <PriceField source="price" sortable={false} /> {/* Combined field */}
          <PriceFieldLocal source="priceLocal" sortable={false} />{' '}
          {/* Combined field */}
          <TextField source="orderId" sortable={false} />
        </Datagrid>
      </List>
      {selectedWithdrawal && (
        <WithdrawalProcessingForm
          withdrawal={selectedWithdrawal}
          onClose={handleClearSelection}
        />
      )}
    </>
  );
};

export default WithdrawalList;
