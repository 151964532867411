import React, { useState } from 'react';
import {
  List,
  Datagrid,
  Pagination,
  TextField,
  DateField,
  Filter,
} from 'react-admin';
import { Link } from 'react-router-dom';
import { Button, Tab, Tabs } from '@material-ui/core';

import NFTShow from './nft.view';

const AllBetsButton = ({ record }) => (
  <Button
    variant="contained"
    color="primary"
    component={Link}
    to={{
      pathname: `/bets/${record.id}`,
      search: `event_name=${encodeURIComponent(record.event_name)}&status=${
        record.status
      }`,
    }}
  >
    All Bets
  </Button>
);

const UserPagination = props => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

const NFTList = props => {
  const [tab, setTab] = useState(0);

  const MyLink = ({ record }) => <Link to={`/events/${record.id}`}>Edit</Link>;

  const rowStyle = record => {
    if (record && record.status === 'closed') {
      if (Number(record.numberOfBets) === 0) {
        return { backgroundColor: '#ffcdd2' };
      } else {
        return { backgroundColor: 'green' };
      }
    } else if (record && record.status === 'settled') {
      return { backgroundColor: 'orange' };
    } else if (record && record.status === 'canceled') {
      return { backgroundColor: 'yellow' };
    } else {
      return { backgroundColor: '#c8e6c9' };
    }
  };

  const handleTabChange = (event, value) => {
    setTab(value);
  };

  const openFilters = {
    ...props.filter,
    status: 'open',
  };
  const closedFilters = {
    ...props.filter,
    status: 'closed',
  };
  const settledFilters = {
    ...props.filter,
    status: 'settled',
  };
  const canceledFilters = {
    ...props.filter,
    status: 'canceled',
  };

  return (
    <React.Fragment>
      <Tabs value={tab} onChange={handleTabChange}>
        <Tab label="open" />
        <Tab label="closed" />
        <Tab label="settled" />
        <Tab label="canceled" />
      </Tabs>
      {tab === 0 && (
        <List
          {...props}
          perPage={100}
          sort={{ field: 'event_start_date', order: 'DESC' }}
          filter={openFilters}
        >
          <Datagrid
            rowClick="expand"
            expand={<NFTShow />}
            rowStyle={rowStyle}
            pagination={<UserPagination />}
          >
            {/* <TextField source="id" sortable={false} /> */}
            <TextField source="event_name" sortable={false} />
            <TextField source="numberOfBets" sortable={false} />

            <DateField source="event_start_date" sortable={false} showTime />
            <DateField source="event_end_date" sortable={false} showTime />
            <DateField
              source="event_settlement_date"
              sortable={false}
              showTime
            />

            <TextField source="is_active" sortable={false} />
            <TextField source="status" sortable={false} />

            <MyLink />
            <AllBetsButton />
          </Datagrid>
        </List>
      )}
      {tab === 1 && (
        <List
          {...props}
          perPage={100}
          sort={{ field: 'event_start_date', order: 'DESC' }}
          filter={closedFilters}
        >
          <Datagrid
            rowClick="expand"
            expand={<NFTShow />}
            rowStyle={rowStyle}
            pagination={<UserPagination />}
          >
            {/* <TextField source="id" sortable={false} /> */}
            <TextField source="event_name" sortable={false} />
            <TextField source="numberOfBets" sortable={false} />

            <DateField source="event_start_date" sortable={false} showTime />
            <DateField source="event_end_date" sortable={false} showTime />
            <DateField
              source="event_settlement_date"
              sortable={false}
              showTime
            />
            <TextField source="status" sortable={false} />

            <MyLink />
            <AllBetsButton />
          </Datagrid>
        </List>
      )}
      {tab === 2 && (
        <List
          {...props}
          perPage={100}
          sort={{ field: 'event_settlement_date', order: 'ASC' }}
          filter={settledFilters}
        >
          <Datagrid
            rowClick="expand"
            expand={<NFTShow />}
            rowStyle={rowStyle}
            pagination={<UserPagination />}
          >
            {/* <TextField source="id" sortable={false} /> */}
            <TextField source="event_name" sortable={false} />
            <TextField source="numberOfBets" sortable={false} />
            <DateField
              source="event_actual_settlement_date"
              sortable={false}
              showTime
            />

            <DateField source="event_start_date" sortable={false} showTime />
            <DateField source="event_end_date" sortable={false} showTime />
            <DateField
              source="event_settlement_date"
              sortable={false}
              showTime
            />
            <TextField source="status" sortable={false} />

            <MyLink />
            <AllBetsButton />
          </Datagrid>
        </List>
      )}
      {tab === 3 && (
        <List
          {...props}
          perPage={100}
          sort={{ field: 'event_start_date', order: 'DESC' }}
          filter={canceledFilters}
        >
          <Datagrid
            rowClick="expand"
            expand={<NFTShow />}
            rowStyle={rowStyle}
            pagination={<UserPagination />}
          >
            {/* <TextField source="id" sortable={false} /> */}
            <TextField source="event_name" sortable={false} />
            <TextField source="numberOfBets" sortable={false} />

            <DateField source="event_start_date" sortable={false} showTime />
            <DateField source="event_end_date" sortable={false} showTime />
            <DateField
              source="event_settlement_date"
              sortable={false}
              showTime
            />
            <TextField source="status" sortable={false} />

            <MyLink />
            <AllBetsButton />
          </Datagrid>
        </List>
      )}
    </React.Fragment>
  );
};

export default NFTList;
