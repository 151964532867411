import { useState, useEffect, useRef } from 'react';
import fileToArrayBuffer from 'file-to-array-buffer';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { SaveContextProvider } from 'react-admin';
import moment from 'moment';

import {
  SimpleForm,
  DateInput,
  TextInput,
  useRedirect,
  useNotify,
  SelectInput,
  NumberInput,
  required,
  ImageInput,
  ImageField,
  SelectArrayInput,
  SimpleShowLayout,
  FileInput,
  FileField,
  useLocale,
  Button,
  BooleanInput,
} from 'react-admin';
import { actions } from '../../actions';
import Loader from '../loading';
import { services } from '../../services';
import dataProvider from '../../authentication/dataProvider';

const NFTEdit = props => {
  const notify = useNotify();
  const redirect = useRedirect();
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [data, setData] = useState({});
  const [token, setToken] = useState(null);
  const [callAPI, setCallAPI] = useState(false);
  const [buffer, setBuffer] = useState({ original: null, compressed: null });
  const [size, setSize] = useState({ original: 0, compressed: 0 });
  const [web3Data, setWeb3Data] = useState(null);
  const [uploadType, setUploadType] = useState(null);
  const [ownerAdd, setOwnerAdd] = useState(null);
  const [nftContractInstance, setNftContractInstance] = useState(null);

  const [file, setFile] = useState(null);
  const videoInput = useRef();
  const videoElem = useRef();
  const locale = useLocale();
  const [event, setEvent] = useState(null);

  const [icons] = useState([
    'cricket',
    'football',
    'tennis',
    'badminton',
    'basketball',
    'stocks',
    'sports',
    'crypto',
    'award',
    'movies',
    'film',
    'event',
    'vote',
    'trophy',
    'smartphone',
    'flight',
    'weather',
    'weather2',
    'weather3',
    'instagram',
    'youtube',
    'airplane',
  ]);
  useEffect(() => {
    async function loadCategories() {
      try {
        const response = await actions.getCategories();
        setCategories(response.data);

        async function fetchNFT() {
          const response = await dataProvider.getOne('events', {
            id: props.id,
          });
          //setEvent(response.data);
          setData(response.data); // set data with event data
        }
        fetchNFT();
      } catch (error) {
        console.error('Error loading categories', error);
      }
    }
    loadCategories();
  }, []);

  async function updateEvent() {
    try {
      const response = await services.put(`/events/${props.id}`, data);
      notify('Event updated successfully');
      redirect('/events');
    } catch (error) {
      console.error('Error updating event', error);
    }
  }

  async function handleUploadFile(file) {
    if (file) {
      //setLoading(true);
      const formData = new FormData();
      formData.append('file', file);
      try {
        const response = await actions.upload(formData);
        setData({
          ...data,
          image: { s3Url: response.data.Location, format: uploadType },
        });
      } catch (error) {
        console.error('Error uploading file', error);
      } finally {
        //setLoading(false);
      }
    }
  }

  function handleSave(event) {
    event?.preventDefault?.();
    console.log('data', data);
    setCallAPI(true);
  }

  useEffect(() => {
    if (callAPI) {
      updateEvent();
      setCallAPI(false);
    }
  }, [callAPI]);

  return (
    <SaveContextProvider value={props}>
      <SimpleForm save={handleSave}>
        <h4>Edit Event</h4>
        <SimpleShowLayout>
          <TextInput
            source="event_name"
            defaultValue={data.event_name}
            validate={required()}
            onChange={event =>
              setData({ ...data, event_name: event.target.value })
            }
          />

          <TextInput
            multiline
            rows={8}
            defaultValue={data.event_description}
            format={value => (value ? value.replace(/\n/g, '<br />') : '')}
            parse={value => (value ? value.replace(/<br\s?\/?>/g, '\n') : '')}
            source="event_description"
            validate={required()}
            onChange={event =>
              setData({ ...data, event_description: event.target.value })
            }
          />
        </SimpleShowLayout>

        <h4>Marketplace Setting</h4>
        <SimpleShowLayout>
          <div style={{ marginBottom: '1rem' }}>
            <label>Close Date:</label>
            <input
              type="datetime-local"
              placeholder="Select End date and time"
              style={{ width: '100%' }}
              value={
                data.event_end_date
                  ? moment(data.event_end_date).format('YYYY-MM-DDTHH:mm')
                  : null
              }
              onChange={e => {
                setData({
                  ...data,
                  event_end_date: e.target.value
                    ? moment(e.target.value, 'YYYY-MM-DDTHH:mm').toISOString()
                    : null,
                });
              }}
            />
          </div>

          <div style={{ marginBottom: '1rem' }}>
            <label>Settlement Date:</label>
            <input
              type="datetime-local"
              placeholder="Select Settlement date and time"
              style={{ width: '100%' }}
              value={
                data.event_settlement_date
                  ? moment(data.event_settlement_date).format(
                      'YYYY-MM-DDTHH:mm',
                    )
                  : null
              }
              onChange={e => {
                setData({
                  ...data,
                  event_settlement_date: e.target.value
                    ? moment(e.target.value, 'YYYY-MM-DDTHH:mm').toISOString()
                    : null,
                });
              }}
            />
          </div>
        </SimpleShowLayout>

        <h4>Category </h4>
        <SimpleShowLayout>
          <SelectInput
            source="category"
            choices={categories}
            validate={required()}
            optionValue="_id"
            optionText={type => `${type.name}`}
            defaultValue={data.category}
            onChange={event => {
              setData({ ...data, category: event.target.value });
            }}
          />
        </SimpleShowLayout>

        <BooleanInput
          source="is_active"
          label="Is Active"
          onChange={value => {
            setData({ ...data, is_active: value });
          }}
          defaultValue={data.is_active}
        />
      </SimpleForm>
    </SaveContextProvider>
  );
};

export default NFTEdit;
