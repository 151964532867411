import { services } from '../../services';
let networkId = 1;
async function fetchNetworkId() {
  networkId = await services.getNetworkId();
}
fetchNetworkId();

function getContractAddresses() {
  if (networkId === '0x4' || +networkId === 4)
    return {
      nftContractAddress: '0x2706dCA4e92EC08e7f07b30C2a1d1ea396FF85ba',
    };
  else if (+networkId === 1 || networkId === '0x1')
    return {
      nftContractAddress: '0x2706dCA4e92EC08e7f07b30C2a1d1ea396FF85ba',
    };
  else
    return {
      nftContractAddress: '0x2706dCA4e92EC08e7f07b30C2a1d1ea396FF85ba',
    };
}
export default getContractAddresses;
