import * as React from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  DateField,
  BooleanField,
  ImageField,
  TopToolbar,
  TabbedShowLayout,
  Tab,
  ArrayField,
  SingleFieldList,
  ChipField,
  EditButton,
  Datagrid,
  FunctionField,
  NumberField,
  Labeled,
} from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';
import BackButton from '../backButton';
import { Link } from 'react-router-dom';

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: '150px',
      width: '150px',
      objectFit: 'contain',
      borderRadius: '50%',
    },
  },
});
const customRowStyle = (record, index) => {
  if (record.isDeposit && record.paymentStatus === 'completed') {
    return { backgroundColor: 'green' }; // Rows in green
  }
  if (!record.isDeposit) {
    return { backgroundColor: 'red' }; // Rows in red
  }
  return {};
};
const customRowBankStyle = (record, index) => {
  if (record.status === 'SUCCESS') {
    return { backgroundColor: 'green' }; // Rows in green
  }
};

const CustomNumberField = ({ source, record }) => {
  const value = record[source] ? record[source] * 80 : 0;
  return <span>₹{value.toFixed(0)}</span>;
};
const MultiplyBy80Field = ({ source, record, label }) => {
  const value = record[source] ? record[source] * 80 : 0;
  return (
    <Labeled label={label}>
      <span>₹{value.toFixed(0)}</span>
    </Labeled>
  );
};

const customRowStyleHistory = (record, index) => {
  return { backgroundColor: 'white' }; // Rows in green
};
const TransactionTypeField = ({ record }) => {
  console.log('Record:', record);
  return <span>{record?.isDeposit ? 'Deposit' : 'Withdrawal'}</span>;
};

// Function to conditionally set the row style based on the status
const betRowStyle = (record, index) => {
  if (record.status === 'win') {
    return { backgroundColor: 'green' }; // Rows in green for 'win'
  }
  if (record.status === 'lose') {
    return { backgroundColor: 'orange' }; // Rows in red for 'lose'
  }
  return { backgroundColor: 'grey' }; // Rows in grey for others
};

const UserShow = props => {
  const classes = useStyles();
  console.log('UserShow props:', props.record);

  const UserActions = ({ basePath, data, resource }) => (
    <TopToolbar>
      <BackButton color="primary" style={{ marginLeft: '5rem' }} />
    </TopToolbar>
  );

  return (
    <Show {...props} actions={<UserActions />}>
      <TabbedShowLayout>
        <Tab label="Profile">
          <DateField source="createdAt" showTime />
          <TextField source="email" sortable={false} />
          <TextField source="waName" sortable={false} />
          <TextField source="waNumber" sortable={false} />
          <MultiplyBy80Field source="walletBalance" label="Main Balance" />
          <MultiplyBy80Field source="bonusBalance" label="Bonus Balance" />
          <MultiplyBy80Field source="winBalance" label="Win Balance" />
          <TextField source="referredCount" sortable={false} />
          <TextField source="is_kyc_verified" sortable={false} />
        </Tab>

        <Tab label="Bets">
          <ArrayField source="bets">
            <Datagrid rowStyle={betRowStyle}>
              <DateField source="createdAt" />
              <FunctionField
                label="Event Name"
                render={record => (
                  <Link
                    to={`/bets/${record.event_id._id}?event_name=${record.event_id.event_name}`}
                  >
                    {record.event_id.event_name}
                  </Link>
                )}
              />
              <FunctionField
                label="Bet Amount Info"
                render={record => (
                  <>
                    <div>Invested: {record.bet_amount}</div>
                    <div>Main : {record.main_amount}</div>
                    <div>Bonus : {record.bonus_amount}</div>
                    <div>Winning : {record.from_win_amount}</div>
                  </>
                )}
              />
              <FunctionField
                label="Win Amount Info"
                render={record => (
                  <>
                    <div>Total output: {record.win_amount}</div>
                    <div> Main : {record.win_main_amount}</div>
                    <div> Bonus : {record.win_bonus_amount}</div>
                    <div> Win : {record.win_win_amount}</div>
                  </>
                )}
              />
              <TextField source="status" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Transactions">
          <ArrayField source="transactions">
            <Datagrid rowStyle={customRowStyle}>
              {/* Assuming transactions have a similar structure */}
              <TransactionTypeField source="isDeposit" />
              <DateField source="createdAt" showTime />
              <TextField source="paymentStatus" /> {/* Deposit/Withdrawal */}
              <TextField source="priceAmountLocal" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Wallet History">
          <ArrayField source="walletHistory">
            <Datagrid rowStyle={customRowStyleHistory}>
              <DateField source="createdAt" label="Created At" showTime />

              <TextField source="type" label="Transaction Type" />
              <CustomNumberField source="change" label="Change" />
              <CustomNumberField
                source="changeInWinBalance"
                label="Change in Win Balance"
              />

              <CustomNumberField
                source="changeInWalletBalance"
                label="Change in Main Balance"
              />
              <CustomNumberField
                source="changeInBonusBalance"
                label="Change in Bonus Balance"
              />
              <CustomNumberField source="walletBalance" label="Main Balance" />
              <CustomNumberField source="winBalance" label="Win Balance" />

              <CustomNumberField source="bonusBalance" label="Bonus Balance" />
              <TextField source="status" label="Status" />
              <TextField source="transactionId" label="Transaction ID" />
              <TextField source="betId" label="Bet ID" />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="User Bank Details">
          <ArrayField source="userBanks">
            <Datagrid rowStyle={customRowBankStyle}>
              <DateField source="createdAt" label="Created At" showTime />
              <TextField source="status" label="Status" />

              <TextField source="accountNumber" label="Account Number" />
              <TextField source="accountName" label="Account Name" />
              <TextField source="ifsc" label="IFSC Code" />
              <TextField source="branchAddress" label="Branch Address" />
              <BooleanField source="isActive" label="Is Active" />
              <TextField source="referenceNumber" label="Reference Number" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  );
};

export default UserShow;
