import React, { useState, useCallback } from 'react';
import {
  Create,
  ImageField,
  ImageInput,
  required,
  SimpleForm,
  DateTimeInput,
  TextInput,
  ReferenceInput,
  SelectInput,
  useNotify,
  AutocompleteInput,
  useDataProvider,
  minValue,
} from 'react-admin';
import { services } from '../../services';
import { debounce } from 'lodash';
import moment from 'moment';

const NotificationsPage = props => {
  const notify = useNotify();
  const dataProvider = useDataProvider();

  const [users, setUsers] = useState([]);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const handleCategoryChange = event => {
    setSelectedCategory(event.target.value);
  };

  const handleSubmit = async data => {
    let formData = new FormData();

    formData.append('title', data.title);
    formData.append('body', data.body);
    formData.append('image', data.image?.rawFile);
    formData.append('userCategory', data.userCategory);
    formData.append('userId', data.userId);
    if (data?.dateSent)
      formData.append('dateSent', data?.dateSent.toISOString());

    try {
      const response = await services.post(`/notification/create`, formData, {
        'Content-Type': 'multipart/form-data',
      });
      notify(`Notification sent Successfully`, `success`);
      console.log('response', response);
    } catch (error) {
      notify(`Error sending notification`, `warning`);
      console.error('Error sending notification:', error);
    }
  };

  return (
    <Create title="Create a Notification" {...props}>
      <SimpleForm save={handleSubmit}>
        <ImageInput
          source="image"
          label="Image (not required)"
          accept="image/*"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
        <TextInput
          source="title"
          label="Notification Title"
          validate={required()}
        />
        <TextInput
          source="body"
          label="Notification Body"
          multiline
          validate={required()}
        />

        <SelectInput
          source="userCategory"
          onChange={handleCategoryChange}
          validate={required()}
          choices={[
            { id: 'individualUser', name: 'Individual' },
            { id: 'active', name: 'Active Users' },
            { id: 'lapsed', name: 'Lapsed Users' },
            { id: 'new', name: 'New Users' },
            {
              id: 'placedBets',
              name: 'Users who have placed bets at least once on the portal.',
            },
            {
              id: 'placedOrder',
              name: 'Users who have placed order at least once on the portal.',
            },
            { id: 'winPool', name: 'Users who win in an pool.' },
            { id: 'winOrder', name: 'Users who win in an order.' },
            { id: 'lostPool', name: 'Users who lose in an pool.' },
            { id: 'lostOrder', name: 'Users who lose in an order.' },
          ]}
        />

        {selectedCategory === 'individualUser' && (
          <TextInput source="userId" label="User ID" />
        )}
        <DateTimeInput
          label="Select Notification date Sent"
          source="dateSent"
          type="datetime-local"
        />
      </SimpleForm>
    </Create>
  );
};

export default NotificationsPage;
