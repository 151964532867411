import { web3 } from '../web3';

export const web3Services = {
  getNetworkId,
  //enableMetamask,
  getContractInstance,
};

async function getNetworkId() {
  try {
    return await window.ethereum.request({ method: 'eth_chainId' });
  } catch (error) {
    return 1;
  }
}
/* 
async function enableMetamask() {
  try {
    await window.ethereum.send("eth_requestAccounts");
    window.location.reload();
    return true;
  } catch (error) {
    if (error.code === -32002) {
      return false;
    }
    return false;
  }
} */

async function getContractInstance(contractAbi, contractAddress) {
  try {
    if (web3) {
      const contractInstance = await new web3.eth.Contract(
        contractAbi,
        contractAddress,
      );
      return contractInstance;
    }
  } catch (error) {
    console.log(error);
  }
}
