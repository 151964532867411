import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import {
  List,
  Datagrid,
  FunctionField,
  TextField,
  Pagination,
  DateField,
  DatagridBody,
  ShowButton,
  ImageField,
  EmailField,
  BooleanField,
  TextInput,
  Filter,
} from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { makeStyles } from '@material-ui/core/styles';
import ApproveViewsButton from './approve.view.buttons';
import ApproveButton from './approve.user';
// import UserFilters from "./user.filters";
import { Button, CircularProgress } from '@material-ui/core';
import { services } from '../../services';
import Alert from '@material-ui/lab/Alert';

const UserBulkActionButtons = props => {
  return (
    <Fragment>
      <ApproveViewsButton {...props} />
    </Fragment>
  );
};

const UserDatagridRow = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  basePath,
  className,
}) => {
  return (
    <TableRow key={id} className={className}>
      {/* first column: selection checkbox */}
      {/* <TableCell padding="none">
                {record.status !== 'pending' && <Checkbox
                    disabled={(record.status === 'APPROVED' && record.role.roleName === 'CREATOR') || (record.role.roleName === 'COLLECTOR')}
                    onClick={event => onToggleItem(record, event)}
                />}
            </TableCell> */}
      {/* data columns based on children */}
      {React.Children.map(children, field => (
        <TableCell key={`${id}-${field.props.source}`}>
          {React.cloneElement(field, {
            record,
            basePath,
            resource,
          })}
        </TableCell>
      ))}
    </TableRow>
  );
};
const UserDatagridBody = props => (
  <DatagridBody {...props} row={<UserDatagridRow />} />
);
const UserDatagrid = props => (
  <Datagrid {...props} body={<UserDatagridBody />} />
);

const UserShowButton = ({ record }) => (
  <ShowButton basePath="/users" label="Show" record={record} />
);

const useStyles = makeStyles({
  imgContainer: {
    '& img': {
      height: '50px',
      width: '50px',
      objectFit: 'contain',
      borderRadius: '50%',
    },
  },
});
const UserFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by WA Number" source="waNumber" alwaysOn />
  </Filter>
);
const UserPagination = props => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

// Create a new Field component
const ResendEmailButton = ({ record }) => {
  const [emailSent, setEmailSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const sendEmail = async () => {
    setLoading(true);
    console.log(`Resending email to ${record.id}`);
    try {
      const response = await services.post(`/users/reSendEmail`, {
        userId: record.id,
      });
      console.log(response.data);
      if (response.status === 200) {
        setEmailSent(true);
        //alert('Email has been sent!');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  // Only display the button if the email is not verified
  if (!record.is_email_verified) {
    return (
      <div>
        <Button
          color="primary"
          onClick={sendEmail}
          disabled={emailSent || loading}
        >
          Resend email
        </Button>
        {emailSent && <Alert severity="success">Email sent!</Alert>}
        {loading && <CircularProgress size={24} />}
      </div>
    );
  }

  // If the email is verified, don't display anything
  return null;
};

const Users = props => {
  const classes = useStyles();

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="List of Users"
      perPage={20}
      pagination={<UserPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<UserFilters />}
    >
      <Datagrid>
        <DateField source="createdAt" label="Date" showTime />

        {/*  <FunctionField
          label="Name"
          render={(record) => `${record.first_name} ${record.last_name}`}
          sortable={false}
        /> */}
        <TextField source="waName" sortable={false} />

        <TextField source="waNumber" sortable={false} />

        <TextField source="is_app" sortable={false} />

        <TextField source="is_referred" sortable={false} />

        <TextField source="referredCount" label="RefCount" sortable={true} />

        <TextField source="walletBalance" label="Main" sortable={true} />
        <TextField source="bonusBalance" label="Bonus" sortable={false} />
        <ShowButton basePath="/users" label="Details" />
        {/*         <BooleanField source="is_email_verified" label="IsVerified?" sortable={false} />
         */}

        {/*         <ResendEmailButton label="Resend email" sortable={false} />
         */}

        <TextField source="id" sortable={false} />
      </Datagrid>
    </List>
  );
};

export default Users;
