import { services } from '../services';

export const userActions = {
  createUserByAdmin,
  getCategories,
  getUserCollections,
  getUsersByRoleId,
  getAllUsersByRoleId,
  getCategoriesForAdmin,
  getRoles,
  upload,
};

function getCategoriesForAdmin() {
  const response = services.get(`/category/list?list=all`);
  return response.then(promise => {
    if (promise) {
      return promise.data;
    } else {
      console.log('error in actions getCategoriesForAdmin ');
    }
  });
}

function getCategories() {
  const response = services.get(`/category`);
  return response.then(promise => {
    if (promise) {
      return promise.data;
    } else {
      console.log('error in actions getCategories ');
    }
  });
}

function getUserCollections(user_id) {
  const response = services.get(`/nft/listCollection/${user_id}`);
  return response.then(promise => {
    if (promise) {
      return promise.data;
    } else {
      console.log('error in actions getUserCollections ');
    }
  });
}

function getUsersByRoleId(roleId) {
  const response = services.get(`/user/list?roleId=${roleId}`);
  return response.then(promise => {
    if (promise) {
      return promise.data.data;
    } else {
      console.log('error in actions getCreators ');
    }
  });
}

function upload(params) {
  const response = services.post(`/upload`, params);
  return response.then(promise => {
    if (promise) {
      return promise;
    } else {
      console.log('error in actions getCreators ');
    }
  });
}

function getAllUsersByRoleId(roleId) {
  const response = services.get(`/user/list?roleId=${roleId}&pagination=false`);
  return response.then(promise => {
    if (promise) {
      return promise.data.data;
    } else {
      console.log('error in actions getCreators ');
    }
  });
}

function getRoles() {
  const response = services.get(`/user/getRoles`);
  return response.then(promise => {
    if (promise) {
      return promise.data.data;
    } else {
      console.log('error in actions getCreators ');
    }
  });
}

function createUserByAdmin(params) {
  const response = services.post(`/user/addNewUserByAdmin`, params);
  return response.then(promise => {
    if (promise) {
      return promise;
    } else {
      console.log('error in actions getCreators ');
    }
  });
}
