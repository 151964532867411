import React, { useState } from 'react';
import {
  List,
  Datagrid,
  Pagination,
  TextField,
  DateField,
  Filter,
} from 'react-admin';
import { Link } from 'react-router-dom';
import LiveEventShow from './live-event.view';

const LiveEventList = props => {
  const EventLink = ({ record }) => (
    <Link to={`/live-events/${record.id}`}>Edit</Link>
  );

  const rowStyle = record => {
    if (record?.isActive) {
      return { backgroundColor: 'green' };
    } else {
      return { backgroundColor: '#c8e6c9' };
    }
  };

  const openFilters = {
    ...props.filter,
    status: 'open',
  };

  return (
    <React.Fragment>
      <List {...props} hasCreate={false} exporter={false} pagination={null}>
        <Datagrid rowClick="expand" rowStyle={rowStyle} pagination={null}>
          <TextField source="event_name" sortable={false} />

          <DateField source="event_start_date" sortable={false} showTime />
          <DateField source="event_end_date" sortable={false} showTime />
          <TextField source="is_active" sortable={false} />

          <EventLink />
        </Datagrid>
      </List>
    </React.Fragment>
  );
};

export default LiveEventList;
