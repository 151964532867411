import contractAddresses from '../contractData/contractAddress/addresses';
import nftABI from '../contractData/abis/nft.json';
import { services } from '../services';

export const web3Actions = {
  getNetworkId,
  getNFTContractInstance,
};

function getNFTContractInstance() {
  const { nftContractAddress } = contractAddresses();
  const response = services.getContractInstance(nftABI, nftContractAddress);
  return response.then(promise => {
    if (promise) {
      return promise;
    } else {
      console.log('error in actions  getNFTContractInstance ');
    }
  });
}

function getNetworkId() {
  return () => {
    const response = services.getNetworkId();
    response.then(promise => {
      if (promise) {
        console.log(promise);
      } else {
        console.log('error in actions  getNetworkId ');
      }
    });
  };
}
