import * as React from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';

import { useState, useEffect } from 'react';
import { services } from '../../services';

const Dashboard = () => {
  const [state, setState] = useState({
    activeEventsCount: 0,
    totalEventsCount: 0,
    totalUsersCount: 0,
    verifiedUsersCount: 0,
    totalBetsCount: 0,
    totalBetAmount: 0,
    walletBalanceSum: 0,
    bonusBalanceSum: 0,
  });

  useEffect(() => {
    async function getCounts() {
      try {
        const response = await services.get(`/dashboard`);
        console.log(response.data);
        setState(response.data);
      } catch (error) {
        console.error(error);
      }
    }
    getCounts();
  }, []);

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', margin: '-10px' }}>
      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Registered Users" />
        <CardContent>
          <div>{state.totalUsersCount}</div>
        </CardContent>
      </Card>

      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Verified Users" />
        <CardContent>
          <div>{state.verifiedUsersCount}</div>
        </CardContent>
      </Card>
      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Installed Apps" />
        <CardContent>
          <div>{state.usersWithDeviceIdCount}</div>
        </CardContent>
      </Card>

      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Total Wallet  Balance" />
        <CardContent>
          <div>$ {state.walletBalanceSum}</div>
        </CardContent>
      </Card>

      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Total Bonus Balance" />
        <CardContent>
          <div>$ {state.bonusBalanceSum}</div>
        </CardContent>
      </Card>

      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Active Events" />
        <CardContent>
          <div>{state.activeEventsCount}</div>
        </CardContent>
      </Card>

      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Total Events" />
        <CardContent>
          <div>{state.totalEventsCount}</div>
        </CardContent>
      </Card>

      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Total Bets Placed" />
        <CardContent>
          <div>{state.totalBetsCount}</div>
        </CardContent>
      </Card>

      <Card style={{ flexBasis: '33.33%', margin: '10px' }}>
        <CardHeader title="Total Bet Amount" />
        <CardContent>
          <div>$ {state.totalBetAmount}</div>
        </CardContent>
      </Card>
    </div>
  );
};

export default Dashboard;
