import React from 'react';
import {
  List,
  Datagrid,
  DateField,
  TextField,
  Pagination,
  ShowButton,
  Filter,
  TextInput,
} from 'react-admin';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import { Link } from 'react-router-dom';

const PredictionFilters = props => (
  <Filter {...props}>
    <TextInput label="Search by Event Id" source="eventId" alwaysOn />
  </Filter>
);

const PredictionPagination = props => (
  <Pagination rowsPerPageOptions={[]} {...props} />
);

const PredictionsList = props => {
  const WaNumberField = ({ record }) => (
    <Link to={`/users/${record.user_id}/show`}>
      {record.user_waNumber ? record.user_waNumber : 'Email'}
    </Link>
  );

  return (
    <List
      {...props}
      bulkActionButtons={false}
      title="List of Predictions"
      perPage={20}
      pagination={<PredictionPagination />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<PredictionFilters />}
    >
      <Datagrid>
        <DateField source="createdAt" label="Created At" showTime />
        <TextField source="event_name" label="Event Name" />
        <WaNumberField source="user_waNumber" label="waNumber" />
        <TextField source="bet_result" label="Prediction" />
        <TextField source="bet_amount" label="Amount" />
        <TextField source="main_amount" label="Main Amount" />
        <TextField source="bonus_amount" label="Bonus Amount" />
        <TextField source="win_amount" label="Win Amount" />
        <TextField source="status" label="Status" />
        <ShowButton basePath="/predictions" label="Details" />
        <TextField source="id" />
      </Datagrid>
    </List>
  );
};

export default PredictionsList;
