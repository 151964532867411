import React from 'react';
import { Admin, Resource, usePermissions } from 'react-admin';
import { createBrowserHistory as createHistory } from 'history';
import UserIcon from '@material-ui/icons/Group';
import { AccountTreeSharp } from '@material-ui/icons';

import dataProvider from './authentication/dataProvider';
import AuthProvider from './authentication/authProvider';
import Dashboard from './components/dashoboard/dashboard';
import NotFound from './components/notFound';
import UserList from './components/users/users';
import UserShow from './components/users/user.view';
import UserEdit from './components/users/user.edit';
import CategoriesList from './components/categories/categories';
import CategoryShow from './components/categories/category.view';
import CategoryCreate from './components/categories/category.create';
import CategoryEdit from './components/categories/category.edit.jsx';
import NFTList from './components/nft/nfts';
import NFTCreate from './components/nft/nft.create';
import NFTShow from './components/nft/nft.view';
import NFTEdit from './components/nft/nft.edit';
import PredictionsList from './components/Predictions/Predictions';

import LiveEventList from './components/live-events/live-events';
import LiveEventCreate from './components/live-events/live-event.create';
import LiveEventShow from './components/live-events/live-event.view';
import LiveEventEdit from './components/live-events/live-event.edit';

import BetsList from './components/bets/bets.view';
import DepositList from './components/deposits/deposits';
import WithdrawalList from './components/withdrawals/withdrawals';
import NotificationsPage from './components/notifications/notifications';

import contactList from './components/contact/contact';

import { theme } from './theme';

import CategoryIcon from '@material-ui/icons/Category';
import EventIcon from '@material-ui/icons/Event';
import PredictionIcon from '@material-ui/icons/Poll';
import BetIcon from '@material-ui/icons/MonetizationOn';
import DepositIcon from '@material-ui/icons/AccountBalanceWallet';
import WithdrawalIcon from '@material-ui/icons/MoneyOff';
import ContactIcon from '@material-ui/icons/Contacts';
import NotificationIcon from '@material-ui/icons/Notifications';

const history = createHistory({ basename: '/admin' });

const App = () => {
  const { permissions } = usePermissions();
  //console.log("permisssions:::::", permissions)

  /* useEffect(() => {
      AuthProvider.getPermissions()
        .then((permissionsResponse) => {
          console.log("permission::",permissionsResponse)
          setPermissions(permissionsResponse);
        })
        .catch((error) => {
          console.error("Failed to fetch permissions", error);
        });
    }, []);

    if (permissions === null) {
      // The permissions are still loading
      return null;
    } */

  return (
    <Admin
      title="React Admin"
      history={history}
      authProvider={AuthProvider}
      dataProvider={dataProvider}
      dashboard={Dashboard}
      NotFound={NotFound}
      theme={theme}
    >
      {permissions => [
        // Users resource visible to all users

        <Resource
          name="events"
          show={NFTShow}
          list={NFTList}
          create={NFTCreate}
          edit={NFTEdit}
          options={{ label: 'Events' }}
          icon={EventIcon}
        />,
        permissions === 'Admin' ? (
          <Resource
            name="live-events"
            show={LiveEventShow}
            list={LiveEventList}
            create={LiveEventCreate}
            edit={LiveEventEdit}
            options={{ label: 'Live Events Config' }}
            icon={AccountTreeSharp}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="users"
            list={UserList}
            show={UserShow}
            edit={UserEdit}
            options={{ label: 'Users' }}
            icon={UserIcon}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="categories"
            list={CategoriesList}
            show={CategoryShow}
            create={CategoryCreate}
            edit={CategoryEdit}
            options={{ label: 'Categories' }}
            icon={CategoryIcon}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="predictions"
            list={PredictionsList}
            options={{ label: 'Predictions' }}
            icon={PredictionIcon}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="bets"
            list={BetsList}
            options={{ label: 'NA' }}
            icon={BetIcon}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="deposits"
            list={DepositList}
            options={{ label: 'Deposits' }}
            icon={DepositIcon}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="withdrawals"
            list={WithdrawalList}
            options={{ label: 'Withdrawals' }}
            icon={WithdrawalIcon}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="contacts"
            list={contactList}
            options={{ label: 'Contacts' }}
            icon={UserIcon}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="contacts"
            list={contactList}
            options={{ label: 'Contacts' }}
            icon={ContactIcon}
          />
        ) : null,
        permissions === 'Admin' ? (
          <Resource
            name="notifications"
            list={NotificationsPage}
            options={{ label: 'Send notification' }}
            icon={NotificationIcon}
          />
        ) : null,
      ]}
    </Admin>
  );
};

export default App;
